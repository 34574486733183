body{
    scroll-behavior: smooth;
    font-family: 'Rubik', sans-serif;
}
html{
    scroll-behavior: smooth
}
.footer-dot-par {
    position: relative;
}

.footer-dot-par li {
    padding-left: 25px;
    position: relative;
}

.footer-dot-par li::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #1f86ef;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.scrolll_btn{
    transition: 0.6s all linear;
}
.scrolll_btn.scroll_btn_show{
    transform: translateY(-100%);
    top: 0;
}
.book_taxi{
    position: relative;
    transition: 0.5s ease;
}
.book_taxi:hover{
    background-color: transparent;
    color: #000;
}
.book_taxi:before , .book_taxi_inverse::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #f79324;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    transform: scale(1, 0);
    z-index: -1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}
.book_taxi_inverse::before{
    background-color: #0c3646;
    color: #fff;
}
.book_taxi:hover::before ,.book_taxi_inverse:hover::before {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
     transform-origin: center center; 
     -webkit-transform: scale(1); 
    -ms-transform: scale(1);
    transform: scale(1);
}
.call_now_btn{
    position: relative;
}
.call_now_btn:hover {
    color: #fff;
    border-color: #1f86ef;
    transition: all ease .5s;
    background-color: transparent;
    z-index: 1;
}
.call_now_btn:hover::before {
    width: 60%;
    height: 100%;
}
.call_now_btn:hover::after {
    width: 60%;
    height: 100%;
}
.call_now_btn::after {
    position: absolute;
    content: "";
    background-color: #1f86ef;
    height: 100%;
    width: 0%;
    border-radius: 5px 0 0 5px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    top: 0;
    left: 0;
    transition: all ease .5s;
    z-index: -1;
}
.call_now_btn::before {
    position: absolute;
    content: "";
    background-color: #202124;
    height: 100%;
    width: 0%;
    border-radius: 0 5px 5px 0;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    bottom: 0;
    right: 0;
    transition: all ease .5s;
    z-index: -1;
}
.car_icon_par{
    display: inline-block;
    width: 60px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    outline: 6px solid #ffff;
}
.car_icon_par .car_icon_child{
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.dis_scroll{
    overflow: hidden;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.react-datepicker-wrapper{
    width: 100%;
}
.form_shadow{
    position: relative;
    border: 0;
    background: #0597ff;
    border-radius: 8px;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0,0,0.58,1),
    background 0.3s cubic-bezier(0,0,0.58,1);
    cursor: pointer;
    padding: 10px;
}
.form_shadow:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    filter: blur(4px);
    border-radius: inherit;
    top: 0;
    left: 0;
    transform: translate3d(0, .7em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.form_shadow:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0ea5e9;
    border-radius: inherit;
    transform: translate3d(0, 0.5em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.banner_heading{
    line-height: 1.5;
}
.banner_parent::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000000af;
    top: 0;
    position: absolute;
    right: 0;
}
.banner_top .arrow_key{
    color: white;
}
.ReactModalPortal .ReactModal__Overlay{
    z-index: 1000 !important;
}
.enquiry_body{
    overflow: hidden;
}
.enquiry_body .ReactModalPortal .ReactModal__Content{
    width: 80%;
    margin: auto;
    left: 50% !important;
    transform: translateX(-50%);
}
.abt_banner::after , .contact_par::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000006d;
}

/* wave */

/* waves */
.ocean {
    height: 80px; /* change the height of the waves here */
    width: 100%;
    position: relative;
    /* bottom: 0;
    left: 0;
    right: 0; */
    overflow-x: hidden;
  }
  
  .wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }
  
  .wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 18s linear reverse infinite;
    opacity: 0.5;
  }
  
  .wave:nth-of-type(3) {
    bottom: 0;
    animation: wave 20s -1s linear infinite;
    opacity: 0.5;
  }
  .single_pro_parent img{
    transition: 0.34s all ease;
  }
  .single_pro_parent:hover img{
    scale: 1.1;
  }
  .sep_navlilnk{
    transition: .2s all ease;
  }
  .sep_navlilnk::before{
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color:#f79324;
    transition: 0.4s all ease;
  }
  .logo_parent{
    position: absolute;
    background: white;
    z-index: 2;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.34);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px 10px 10px 10px;
  }
  .sep_navlilnk:hover::before{
    height: 100%;
  }
  .blog_topics_par .blog_topics{
    padding-top: 15px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.219);
  }
  .blog_topics_par .blog_topics:first-child{
    padding-top: 0;
    padding-bottom: 10px;
  }
  .blog_topics_par .blog_topics:last-child{
    border-bottom: none;
  }
  @keyframes wave {
      0% {transform: translateX(0);}
      50% {transform: translateX(-25%);}
      100% {transform: translateX(-50%);}
  }
  

@media(max-width: 600px) {
    .enquiry_body .ReactModalPortal .ReactModal__Content{
        width: 90%;
    }
}
@media(max-width: 400px) {
    .enquiry_body .ReactModalPortal .ReactModal__Content{
        width: 95%;
    }
}